<template>
  <div class="index">
    <img class="index_img" src="@/assets/home.png" />
    <el-dialog width="1200px" :visible.sync="isConfirm" :show-close="false">
      <div class="dialog_content">
        <div class="dialog_title">合格投资者认定</div>
        <div class="line"></div>
        <div class="dialog_text">请您确认您或您所代表的机构是符合《中华人民共和国证券投资基金法》、《私募投资基金监督管理暂行办法》、《私募投资基金募集行为管理办法》、《证券期货投资者适当性管理办法》及其他相关法律法规所认定的合格投资者。</div>
        <div class="dialog_text">一、根据我国《私募投资基金监督管理暂行办法》的规定，私募基金合格投资者的标准如下：</div>
        <div class="dialog_mixT">1、具备相应风险识别能力和风险承担能力，投资于单只私募基金的金额不低于100万元且符合下列相关标准的单位和个人：</div>
        <div class="dialog_secT">（1）净资产不低于1000万元的单位；</div>
        <div class="dialog_secT">（2）金融资产不低于300万元或者最近三年个人年均收入不低于50万元的个人。前款所称金融资产包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等。</div>
        <div class="dialog_mixT">2、下列投资者视为合格投资者：</div>
        <div class="dialog_secT">（1）社会保障基金、企业年金等养老基金，慈善基金等社会公益基金；</div>
        <div class="dialog_secT">（2）依法设立并在基金业协会备案的投资计划；</div>
        <div class="dialog_secT">（3）投资于所管理私募基金的私募基金管理人及其从业人员；</div>
        <div class="dialog_secT">（4）中国证监会规定的其他投资者。</div>
        <div class="dialog_text">如果确认您或您所代表的机构是一名“合格投资者”，并将遵守适用的有关法规请点击“接受”键以继续浏览本公司网页。如您不同意任何有关条款，请直接关闭本网页。</div>
        <div class="dialog_boldT">本网页介绍的信息、观点和数据仅供一般性参考，不应被视为购买或销售任何金融产品的某种要约，亦非对任何交易的正式确认。投资有风险，投资产品的过往业绩并不预示其未来表现，本公司不对产品财产的收益状况做出任何承诺或担保，投资者不应依赖本网页所提供的数据做出投资决策，在做出投资决策前应认真阅读相关产品合同及风险揭示等宣传推介文件，并自行承担投资风险。</div>
      </div>
      <div class="line"></div>
      <div class="button" @click="confirm">接受</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.isConfirm = window.sessionStorage.getItem('firstLogin') == null ? true : JSON.parse(window.sessionStorage.getItem('firstLogin'))
  },
  data() {
    return {
      isConfirm: null
    }
  },
  methods: {
    confirm() {
      this.isConfirm = false
      window.sessionStorage.setItem('firstLogin', false)
    }
  }
}
</script>

<style scoped>
.index {
  font-size: 0;
  display: inline-block;
}
.index_img {
  width: 100%;
}
.dialog_content {
  width: 800px;
  margin: 0 auto;
  font-size: 14px;
  color: #333;
  line-height: 24px;
}
.dialog_title {
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  padding: 20px 0 20px;
}
.line {
  height: 2px;
  width: 100%;
  background-color: #999999;
}
.dialog_text {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  text-align: left;
  margin: 20px 0 0;
}
.dialog_mixT {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  text-align: left;
}
.dialog_secT {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  text-align: left;
  text-indent: 1em;
}
.dialog_boldT {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  text-align: left;
  margin-top: 24px;
  font-weight: 600;
  padding-bottom: 20px;
}
.button {
  height: 50px;
  width: 200px;
  background-color: #AD3026;
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  margin: 20px auto 0;
  cursor: pointer;
}
</style>